let api;
export const PROD_API = "https://api.thriftcorp.co/api/v1";
export const STAGING_API = "https://sandbox-api.thriftcorp.co/api/v1";
export const DEV_API = "https://sandbox-api.thriftcorp.co/api/v1";
// export const DEV_API = "https://api.thriftcorp.co/api/v1";

export const checkContain = (link) => {
  if (window.location.href.includes) {
    return window.location.href.includes(link);
  } else {
    return window.location.href.contains(link);
  }
};

export const getApiUrl = () => {
  if (checkContain("app.thriftcorp.co")) {
    api = PROD_API; // Prod API
  } else if (checkContain("staging.thriftcorp.co")) {
    api = STAGING_API; // Staging API
  }
  else if (checkContain("thriftcorp.co")) {
    api = PROD_API; // Staging API
  } else if (checkContain("localhost")) {
    api = STAGING_API; // Dev API
  } else {
    api = DEV_API; // Dev API
  }
  return api;
};
