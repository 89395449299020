import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import empty_loan_config from "../../../../assets/images/empty_state_loan.svg";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { LoanProductCard } from "../../../../components/cards/loanProduct";
import { Icon } from "../../../../components/icons/icon";
import { PageLoader } from "../../../../components/loaders/pageLoader";
import { AddLoanProductModal } from "../../../../components/modals/app_modals/addLoanProductModal";
import { EmptyState } from "../../../../components/states/emptyState";
import { useLoans } from "../../../../customHooks/useLoans";

export const LoanConfiguration = ({ mode = "", cooperativeConfiguration }) => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const { fetchAllLoanProducts } = useLoans();

  const loanProducts = useQuery("allLoanProducts", fetchAllLoanProducts, {
    enabled: true,
  });

  useEffect(() => {
    loanProducts?.refetch();
    // eslint-disable-next-line
  }, []);

  const OpenLoanModal = () => {
    setModal("loanProductModal");
    setModalOpen(true);
  };

  const OpenLoanEditModal = () => {
    setModal("loanProductEditModal");
    setModalOpen(true);
  };

  return (
    <ContainerCard className="!px-0">
      {/* Mini header  */}
      <div
        className="
              w-full flex flex-row gap-4
              items-center justify-between
              mb-6
              px-4 md:px-8
              "
      >
        <div
          className="
                font-bold text-base
                "
        >
          Loan Settings
        </div>
        <div className="!w-full !max-w-[170px] md:!max-w-[174px]">
          <Button
            text={
              <div className="flex gap-1 items-center justify-center">
                <Icon svg="plus" width={16} height={16} /> Add a loan product
              </div>
            }
            onClick={() => {
              // Open modal
              OpenLoanModal();
            }}
            type="button"
            className="!text-sm md:!py-2"
            loading={false}
            disabled={false}
            variation="primary"
          />
        </div>
      </div>

      {/* Configuration body */}
      <div className="px-4 md:px-8">
        {loanProducts?.isFetching && <PageLoader />}
        {[0, undefined, null].includes(loanProducts?.data?.length) &&
          !loanProducts?.isFetching && (
            <EmptyState
              image={<img src={empty_loan_config} alt="approvals" />}
              title="No active loan product yet"
              titleClassName="md:text-base"
              subtitle=""
              subtitleClassName=""
              actionText={
                <div className="flex gap-1 items-center justify-center">
                  <Icon svg="plus" width={16} height={16} /> Add a loan product
                </div>
              }
              onClick={() => {
                OpenLoanModal();
              }}
              actionButtonVariant="secondary"
              buttonContainerClassName="!max-w-[174px]"
            />
          )}
        {loanProducts?.data?.length > 0 && !loanProducts?.isFetching && (
          <div className="flex flex-row flex-wrap gap-2">
            {loanProducts?.data?.map((product, idx) => {
              const key = `loanProduct-${idx}`;
              return (
                <LoanProductCard
                  productName={product?.LoanName}
                  creationDate={product?.DateAdded}
                  onClick={() => {
                    setSelectedProduct(product);
                    OpenLoanEditModal();
                  }}
                  key={key}
                />
              );
            })}
          </div>
        )}
      </div>

      <AddLoanProductModal
        modalOpen={modal === "loanProductModal" && modalOpen}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        cooperativeConfiguration={cooperativeConfiguration}
        mode="create"
      />

      <AddLoanProductModal
        modalOpen={modal === "loanProductEditModal" && modalOpen}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        cooperativeConfiguration={cooperativeConfiguration}
        mode="edit"
        selectedProduct={selectedProduct}
      />
    </ContainerCard>
  );
};
